import React, { useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

// Context
import { PageTypeConsumer } from "../components/context/page-type";

// Previews
import { withPreview } from "gatsby-source-prismic";

// Check is mobile
import isMobile from "ismobilejs";

// Utils
import { splitToChunks } from "../utils/split-to-chunks";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
    flex-direction: unset;
    flex-wrap: unset;
    align-items: unset;
    justify-content: unset;
  }

  height: 100vh;
  min-height: 100vh;

  overflow-y: scroll;

  padding: 180px 60px;

  @media (max-width: 768px) {
    padding: 120px 20px;
  }

  & .inner-directors-menu {
    width: 100%;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      display: block;
      flex-direction: unset;
      flex-wrap: unset;
      align-items: unset;
      justify-content: unset;
    }
  }

  & ul {
    z-index: 1;

    flex: 50%;
    max-width: 50%;

    @media (max-width: 768px) {
      flex: 100%;
      max-width: 100%;
    }

    & li {
      display: flex;
      flex-direction: row;
      justify-content: center;

      position: relative;

      text-align: center;
      margin: 0;

      @media (max-width: 768px) {
        display: block;
        flex-direction: unset;
        justify-content: unset;

        width: 100%;
      }

      & a {
        font-size: calc(20px + (42 - 20) * ((100vw - 300px) / (1600 - 300)));
        line-height: 160%;
        letter-spacing: 0.02em;

        color: rgba(253, 252, 252, 0.8);
        transition: 300ms color ease;

        &:hover {
          color: rgb(223, 63, 28);
        }
      }
    }
  }
`;

const VideoBackground = styled.video`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: -1;

  width: 100vw;
  height: 100vh;

  object-fit: cover;
  opacity: 0;

  &.directors-video,
  &.default-video {
    &.ready {
      opacity: 1;
      transition: 350ms opacity ease;
      transition-delay: 250ms;
    }
  }

  &.directors-video {
    z-index: 0;
  }
`;

const MobileBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background: #177f96;
  z-index: -1;
`;

const DirectorsContent = ({ data, setPageType }) => {
  const [isMobileDevice, setIsMobileDevice] = useState(true);
  const [activeVideo, setActiveVideo] = useState(null);

  const [canDefaultVideoPlay, setCanDefaultVideoPlay] = useState(false);
  const [canActiveVideoPlay, setCanActiveVideoPlay] = useState(false);

  useEffect(() => {
    setPageType("the-talent");
  }, []);

  useEffect(() => {
    if (typeof window !== undefined) {
      setIsMobileDevice(isMobile(window.navigator.userAgent).any);
    }
  }, []);

  // Split in group of 2 items
  const splitDirectors = splitToChunks(
    Array.from(data.prismicTheTalent.data.directors),
    2
  );

  const leftDirectors = splitDirectors[0].map((item, index) => (
    <li
      key={`director_link_left_${index}`}
      onMouseEnter={() => {
        if (item.director.document.data.vimeo_mp4_file.url !== "") {
          setActiveVideo(item.director.document.data.vimeo_mp4_file.url);
        }
      }}
      onMouseLeave={() => setActiveVideo(null)}
    >
      <Link to={`/directors/${item.director.document.uid}`}>
        {item.director.document.data.title.text}
        {item.director.document.data.food_and_drink === true && `*`}
      </Link>
    </li>
  ));

  const rightDirectors = splitDirectors[1].map((item, index) => (
    <li
      key={`director_link_right_${index}`}
      onMouseEnter={() => {
        if (item.director.document.data.vimeo_mp4_file.url !== "") {
          setActiveVideo(item.director.document.data.vimeo_mp4_file.url);
        }
      }}
      onMouseLeave={() => setActiveVideo(null)}
    >
      <Link to={`/directors/${item.director.document.uid}`}>
        {item.director.document.data.title.text}
        {item.director.document.data.food_and_drink === true && `*`}
      </Link>
    </li>
  ));

  const videoFilesForPreconnect = data.prismicTheTalent.data.directors
    .filter(item => item.director.document !== null)
    .map((item, index) => (
      <link
        index={`single_preconnect_item_${index}`}
        rel="preconnect"
        as="video"
        href={item.director.document.data.vimeo_mp4_file.url}
      />
    ));

  return (
    <>
      <Helmet
        title={`Directors – Park Village`}
        meta={[
          {
            name: "og:title",
            content: `Directors – Park Village`,
          },
          {
            name: "twitter:title",
            content: `Directors – Park Village`,
          },
        ]}
      >
        <link
          rel="preconnect"
          as="video"
          href={data.prismicTheTalent.data.background_video.url}
        />
        {videoFilesForPreconnect}
      </Helmet>

      <Page>
        <div className="inner-directors-menu">
          <ul className="left-column">{leftDirectors}</ul>
          <ul className="right-column">{rightDirectors}</ul>
        </div>

        {activeVideo !== null && (
          <VideoBackground
            className={`directors-video ${
              canActiveVideoPlay ? `ready` : `loading`
            }`}
            onCanPlay={() => setCanActiveVideoPlay(true)}
            src={activeVideo}
            loop
            autoPlay
            muted
            preload="metadata"
            playsInline
            playing={activeVideo === null ? false : true}
          />
        )}

        <VideoBackground
          className={`default-video ${
            canDefaultVideoPlay ? `ready` : `loading`
          }`}
          onCanPlay={() => setCanDefaultVideoPlay(true)}
          src={data.prismicTheTalent.data.background_video.url}
          loop
          autoPlay
          muted
          preload="metadata"
          playsInline
          playing={activeVideo === null ? true : false}
        />
      </Page>
    </>
  );
};

const TheTalent = ({ data }) => (
  <PageTypeConsumer>
    {({ setPageType }) => (
      <DirectorsContent setPageType={setPageType} data={data} />
    )}
  </PageTypeConsumer>
);

export default withPreview(TheTalent);

export const query = graphql`
  {
    prismicTheTalent {
      data {
        background_video {
          url
        }
        directors {
          director {
            id
            document {
              ... on PrismicDirector {
                uid
                id
                data {
                  title {
                    text
                  }
                  food_and_drink
                  vimeo_mp4_file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
